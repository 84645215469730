import LanguageWinkMapper from 'models/enums/LanguageWinkMapper.enum';
import {useLocation} from 'react-router-dom';

const useSearchParams = () => {
	const {search} = useLocation();

	const params = new URLSearchParams(search);
	const newParams = new URLSearchParams(
		[...params].map(([key, value]) => [key.toLowerCase(), value])
	);
	const getLanguageCode = (langParam: string | null): string | null => {
		if (langParam && langParam in LanguageWinkMapper) {
			return LanguageWinkMapper[langParam as keyof typeof LanguageWinkMapper];
		}
		return langParam;
	};

	const apikeyFromUrl = newParams.get('apikey');
	const userIdFromUrl = newParams.get('userid');
	const roomIdFromUrl = newParams.get('roomid');
	const nicknameFromUrl = newParams.get('nickname');
	const picFromUrl = newParams.get('image');
	const picIdFromUrl = newParams.get('imageid');
	const colorSchemeModeFromUrl = newParams.get('mode');
	const moderFromUrl = newParams.get('moder');
	const roomNameFromUrl = newParams.get('title');
	const statusNameFromUrl = newParams.get('statusname');
	const readonlyFromUrl = newParams.get('readonly');
	const platformFromUrl = newParams.get('platform');
	const osFromUrl = newParams.get('os');
	const osVersionFromUrl = newParams.get('osversion');
	const errorNumberFromUrl = newParams.get('errornumber');
	const interfaceDirectionFromUrl = newParams.get('rtl');
	const appVersionFromUrl = newParams.get('appversion');
	const profileUrlFromUrl = newParams.get('profileurl');
	const authCodeFromUrl = newParams.get('authcode');
	const tempLangFromUrl = newParams.get('lang');

	const langFromUrl = getLanguageCode(tempLangFromUrl);

	return {
		search,
		userIdFromUrl,
		roomIdFromUrl,
		nicknameFromUrl,
		picFromUrl,
		apikeyFromUrl,
		picIdFromUrl,
		colorSchemeModeFromUrl,
		langFromUrl,
		moderFromUrl,
		roomNameFromUrl,
		statusNameFromUrl,
		readonlyFromUrl,
		platformFromUrl,
		osFromUrl,
		osVersionFromUrl,
		errorNumberFromUrl,
		interfaceDirectionFromUrl,
		appVersionFromUrl,
		profileUrlFromUrl,
		authCodeFromUrl,
	};
};

export default useSearchParams;
